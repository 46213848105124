import React from 'react'
import { Link } from 'react-router-dom';
import CGUPart from '../components/CGUPart';
import Footer from '../components/Footer';
import MailPart from '../components/MailPart';
import PlanPart from '../components/PlanPart';
import '../styles/Pages.css';

function HomePage() {

    document.addEventListener('scroll', function () {
        var divs = document.querySelectorAll('.hiddenDiv'); // Sélection de toutes les divs avec la classe .hiddenDiv
        var screenPosition = window.innerHeight * 0.7; // 10% du bas de l'écran

        divs.forEach(function (div) {
            var divPosition = div.getBoundingClientRect().top; // Position de chaque div par rapport au viewport
            if (divPosition < screenPosition) {
                div.classList.add('visibleDiv');
            } else {
                div.classList.remove('visibleDiv'); // Optionnel, réinitialise l'animation si remonté
            }
        });
    });

    return (
        <>
            {/* Introduction */}
            <div className='container-flex position-relative headerPage user-select-none'>
                <div className='position-absolute top-50 start-50 w-md-75 w-50 translate-middle titreZone'>
                    <Link to='/'>
                        <p className='mt-4 mx-md-5 mb-md-3 mb-1 px-md-3 text-dark logo'>ModalFini<code className='text-dark fw-lighter logo'>/&gt;</code></p>
                    </Link>
                    <p className='mx-md-5 text-secondary logo'>Simplifier. Visualiser. Exceller.</p>
                    <h1 className='TitleHome'>Apprenez <br /> et créez avec vos <code className='text-primary'>data</code>🛰️</h1>
                </div>
                <div className='d-flex justify-content-evenly w-50 position-absolute top-100 start-50 translate-middle'>
                    <span className="pb-5 mb-5" data-bs-toggle="offcanvas" data-bs-target="#offcanvasMail" aria-controls="offcanvasMail" style={{ cursor: 'pointer' }}>Nous contacter</span>
                </div>
            </div>

            {/* Problème et Solution */}
            <div className="container-fluid body border-top">

                {/* <div className="scrollIndicatorHome d-flex justify-content-center align-items-end" style={{ height: '30px' }}>
                    <div className='w-50 h-25 rounded-2 shake-vertical shadow' style={{backgroundColor: 'rgb(161, 169, 177)'}}/>
                </div> */}

                {/* Heros section */}
                <div className="container mt-4 px-md-5 px-3">
                    <div className="row my-5 py-5">
                        <div className="col-md-6 col-12 d-flex align-items-middle">
                            <div className="container-fluid px-0">
                                <p className='my-3 fs-4'>Comme de nombreuses entreprises, vous utilisez probablement Excel pour <code className='text-primary'>votre gestion quotidienne</code>. Toutefois, orchestrer efficacement des personnes, des finances et des actions peut devenir un casse-tête.</p>
                                <p className='my-3 fs-4'>Nous créons pour vous <code className='text-primary'>un outil de gestion basé sur Excel</code> </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 d-flex align-items-end">
                            <div className="container p-3 p-md-5">
                                <ul className="list-group list-group-numbered shadow-sm">
                                    <li className="list-group-item list-group-item-success">Centraliser</li>
                                    <li className="list-group-item list-group-item-success">Clarifier</li>
                                    <li className="list-group-item list-group-item-success">Minimiser les erreurs</li>
                                    <li className="list-group-item list-group-item-success">Visualiser</li>
                                    <li className="list-group-item list-group-item-success">Anticiper</li>
                                </ul>
                                <Link to='/Systeme'>
                                    <button type="button" className="btn btn-dark w-100 mt-md-5 mt-3">Système Excel</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Solutions */}
                <div className="container-fluid mt-4">
                    <div className="container mb-4">
                        <p className='fs-3 w-75 text-secondary'>Nos solutions pour une gestion data-driven avec <span className='text-success'>Excel</span></p>
                        <div className="row mt-5 hiddenDiv">
                            <div className="col-md-6 col-12 border-start py-3">
                                <h5 className=''>Système 🧰</h5>
                                <p>
                                    Le 'Système Excel' personnalisé de ModalFini est un dossier informatique complet, agissant comme un mini ERP, qui intègre un système de gestion sophistiqué basé sur le principe ETL, idéal pour centraliser et optimiser la gestion de multiples domaines d'activité grâce à ses fonctionnalités étendues et sa documentation claire.
                                </p>
                                <ul className="list-group">
                                    <li className="list-group-item list-group-item-action">Dossier SYSTÈME</li>
                                    <li className="list-group-item list-group-item-action" style={{ paddingLeft: '50px' }}>↳ Guide</li>
                                    <Link to='/Systeme'>
                                        <li className="list-group-item list-group-item-action text-primary" style={{ paddingLeft: '50px' }}>↳ SYSTÈME</li>
                                    </Link>
                                    <li className="list-group-item list-group-item-action" style={{ paddingLeft: '50px' }}>↳ Dossier DATA</li>
                                    <li className="list-group-item list-group-item-action" style={{ paddingLeft: "100px" }}>↳ Archive DATA</li>
                                    <li className="list-group-item list-group-item-action" style={{ paddingLeft: "100px" }}>↳ Anciennes versions</li>
                                    <li className="list-group-item list-group-item-action" style={{ paddingLeft: "100px" }}>↳ Archive VBA</li>
                                    <li className="list-group-item list-group-item-action" style={{ paddingLeft: "100px" }}>↳ Licence</li>
                                </ul>

                                <div className='d-flex my-4 justify-content-center'>
                                    <Link to='/Systeme'>
                                        <button type="button" className="btn btn-dark border shadow-sm" style={{ width: '200px' }}>Plus</button>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 rounded-2 Illustration ImgSys shadow-sm border" />
                        </div>
                        <div className="row mt-5 hiddenDiv">
                            <div className="col-md-6 col-12 border-start py-3">
                                <h5 className=''>Outil 🔧</h5>
                                <p>
                                    L'Outil Excel' personnalisé de ModalFini est une solution allégée mais puissante, conçue sur le principe ETL pour gérer spécifiquement des domaines tels que la logistique, les finances ou encore les ressources humaines, garantissant clarté et efficacité dans le traitement et l'analyse des données.
                                </p>
                                <ul className="list-group">
                                    <li className="list-group-item list-group-item-action">Dossier OUTIL</li>
                                    <li className="list-group-item list-group-item-action" style={{ paddingLeft: '50px' }}>↳ Guide</li>
                                    <Link to='/Outil'>
                                        <li className="list-group-item list-group-item-action text-primary" style={{ paddingLeft: '50px' }}>↳ OUTIL</li>
                                    </Link>
                                    <li className="list-group-item list-group-item-action" style={{ paddingLeft: '50px' }}>↳ Dossier DATA</li>
                                    <li className="list-group-item list-group-item-action" style={{ paddingLeft: "100px" }}>↳ Archive DATA</li>
                                    <li className="list-group-item list-group-item-action" style={{ paddingLeft: "100px" }}>↳ Licence</li>
                                </ul>
                                <div className='d-flex my-4 justify-content-center'>
                                    <Link to='/Outil'>
                                        <button type="button" className="btn btn-dark border shadow-sm" style={{ width: '200px' }}>Plus</button>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 rounded-2 Illustration ImgOut shadow-sm border" />
                        </div>
                    </div>
                </div>
            </div>

            {/* Avantages */}
            <div className="container-fluid py-5 my-5 py-md-4 hiddenDiv">
                <div className="row py-5 my-5">
                    <p className='fs-3 w-75 mx-5 text-secondary'>Nos solutions vous permettent</p>
                    <div className="col mx-md-5 mx-3 my-5 px-5 border-start">
                        <h5 className='fs-3'>Des économies significatives :</h5>
                        <p className='my-3'>Éviter l’achat d'outils supplémentaires, vous continuez à utiliser Excel, un environnement déjà familier pour vous et votre équipe.</p>
                    </div>
                    <div className="col mx-md-5 mx-3 my-5 px-5 border-start">
                        <h5 className='fs-3'>Une prise de décisions éclairées :</h5>
                        <p className='my-3'>Grâce à nos méthodes avancées d'analyse de données, vous visualisez et anticipez avec précision vos actions futures</p>
                    </div>
                    <div className='d-flex justify-content-center px-5'>
                        <button type="button" className="w-75 mx-md-5 mx-0 btn btn-dark shadow" data-bs-toggle="offcanvas" data-bs-target="#offcanvasMail" aria-controls="offcanvasMail">Se lancer</button>
                    </div>
                </div>
            </div>

            {/* Techno */}
            <div className="container-fluid body">
                <div className='container-fluid text-secondary py-4'>
                    <div className="container">
                        <div className="row d-flex text-center flex-wrap">
                            <div className="imgSpponsor col">
                                <kbd className='bg-light text-dark px-4 shadow-sm'>Excel</kbd>
                            </div>

                            <div className="imgSpponsor col">
                                <kbd className='bg-light text-dark px-4 shadow-sm'>SQL</kbd>
                            </div>

                            <div className="imgSpponsor col">
                                <kbd className='bg-light text-dark px-4 shadow-sm'>PHP</kbd>
                            </div>

                            <div className="imgSpponsor col">
                                <kbd className='bg-light text-dark px-4 shadow-sm'>NodeJS</kbd>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Utilities */}
                <div className="container-fluid hiddenDiv" style={{ paddingTop: '50px', paddingBottom: '50px' }}>
                    <div className="container mb-4">
                        <div className="row my-4">
                            <p className='fs-3 w-75 text-secondary'>Avec de la DATA on peut</p>
                            <div className="col-md-6 col-12 border-start py-3">
                                <h5>Décrire 📦</h5>
                                <p className='pe-md-4'>
                                    En exploitant efficacement ces données, nous vous aidons à peindre un tableau précis de votre état actuel et de votre progression. Nos solutions Excel personnalisées transforment vos données brutes en insights clairs.
                                </p>
                            </div>
                            <div className="col d-flex border justify-content-center px-3 py-4 bg-light rounded-2 shadow-sm align-items-center text-center">
                                <span className='fs-3'><kbd className='bg-light text-dark'>50 articles en Stock, <br /> celui-ci est à moitié plein </kbd></span>
                            </div>
                        </div>
                        <div className="row my-4">
                            <div className="col-md-6 col-12 border-start py-3">
                                <h5>Diagnostiquer 🩺</h5>
                                <p className='pe-md-4'>
                                    ModalFini vous aide à identifier rapidement les défis et irrégularités dans votre activité. Nos solutions Excel sur mesure transforment vos données complexes en analyses précises, révélant des problèmes cachés et des opportunités d'amélioration.
                                </p>
                            </div>
                            <div className="col d-flex border justify-content-center px-3 py-4 bg-light rounded-2 shadow-sm align-items-center text-center">
                                <span className='fs-3'><kbd className='bg-light text-dark'>5 articles par jours depuis 10 jours</kbd></span>
                            </div>
                        </div>
                        <div className="row my-4">
                            <div className="col-md-6 col-12 border-start py-3">
                                <h5>Prédire 🔭</h5>
                                <p className='pe-md-4'>
                                    Chez ModalFini, nous exploitons le potentiel prédictif de vos données pour anticiper les défis et saisir les opportunités à venir. Nos solutions Excel sur mesure vous permettent de transformer vos données en prévisions fiables, vous offrant une vision prospective de votre activité.
                                </p>
                            </div>
                            <div className="col d-flex border justify-content-center px-3 py-4 bg-light rounded-2 shadow-sm align-items-center text-center">
                                <span className='fs-3'> <kbd className='bg-light text-dark'>Stock plein dans 10 jours</kbd></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Phrase d'accroche */}
            <div className="container hiddenDiv" style={{ paddingTop: '100px', paddingBottom: '100px' }}>
                <div className='my-5'>
                    <p className='mx-md-5 mx-2 px-3 fs-2'>Des solutions pensées pour décomposer la complexité de vos données et améliorer votre façon de travailler</p>
                    <p className='mt-4 mx-5 px-3'>ModalFini<code className='text-dark fs-6 fw-lighter'>/&gt;</code></p>
                    <p className='mx-5 px-3 text-secondary logo'>Simplifier. Visualiser. Exceller.</p>
                </div>
            </div>

            <Footer />
            <MailPart />
            <CGUPart />
            <PlanPart />
        </>
    )
}

export default HomePage