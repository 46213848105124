import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import MailPart from '../components/MailPart';
import CGUPart from '../components/CGUPart';
import PlanPart from '../components/PlanPart';
import '../styles/Pages.css';

function ToolsPage() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  document.addEventListener('scroll', function () {
    var divs = document.querySelectorAll('.hiddenDiv'); // Sélection de toutes les divs avec la classe .hiddenDiv
    var screenPosition = window.innerHeight * 0.7; // 10% du bas de l'écran

    divs.forEach(function (div) {
      var divPosition = div.getBoundingClientRect().top; // Position de chaque div par rapport au viewport
      if (divPosition < screenPosition) {
        div.classList.add('visibleDiv');
      } else {
        div.classList.remove('visibleDiv'); // Optionnel, réinitialise l'animation si remonté
      }
    });
  });

  return (
    <>
      <div className='container-flex position-relative headerPageSeconde'>
        <div className='position-absolute top-50 start-50 w-md-75 w-50 translate-middle titreZone'>
          <Link to='/'>
            <p className='mt-4 mx-5 px-3 text-dark logo'>ModalFini<code className='text-dark fw-lighter logo'>/&gt;</code></p>
          </Link>
          <div className='text-center'>
            <h1 className='TitleHome'>Outil Excel 🔧</h1>
          </div>
        </div>
      </div>

      <div className="container-fluid body border-top pb-5" style={{ minHeight: '100vh' }}>
        <div className="container mt-4">

          <div className="row align-items-end">
            <div className="col-md-6 col-12">
              <div className="container p-3 p-md-5">
                <Link to='/'>
                  <button type="button" className="btn btn-light w-100">Home</button>
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="container p-3 p-md-5">
                <p>Besoin d'un système plus complet ?</p>
                <Link to='/Systeme'>
                  <button type="button" className="btn btn-dark border w-100 shadow">Système Excel</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="w-100 rounded-2 Illustration ImgOut shadow-sm border" />

          <ul className="list-group mt-5 shadow-sm hiddenDiv">
            <li className="list-group-item list-group-item-action">Dossier OUTIL</li>
            <li className="list-group-item list-group-item-action" style={{ paddingLeft: '50px' }}>↳ Guide</li>
            <li className="list-group-item list-group-item-action text-primary" style={{ paddingLeft: '50px' }}>↳ OUTIL</li>
            <li className="list-group-item list-group-item-action" style={{ paddingLeft: '50px' }}>↳ Dossier DATA</li>
            <li className="list-group-item list-group-item-action" style={{ paddingLeft: "100px" }}>↳ Archive DATA</li>
            <li className="list-group-item list-group-item-action" style={{ paddingLeft: "100px" }}>↳ Licence</li>
          </ul>

          <div className="container p-3 p-md-5">
            <button type="button" className="btn btn-dark w-100 shadow" data-bs-toggle="offcanvas" data-bs-target="#offcanvasMail" aria-controls="offcanvasMail">Nous contacter</button>
          </div>

          <div className="row mt-5">
            <div className="col-md-6 col-12 border-start py-3 hiddenDiv">
              <h5 className=''>Description générale</h5>
              <p>
                Cette option est parfaite pour les entreprises qui nécessitent une solution plus ciblée, gérant un aspect spécifique de leur activité, comme la logistique, les finances ou encore les ressources humaines. Cela permet une spécialisation plus poussée et une optimisation des processus dans un domaine particulier. Tout comme pour le "Système Excel", l'approche ETL est un excellent moyen d'assurer la clarté et la précision des données manipulées.
              </p>

              <p className='fw-bold'>Avantages</p>
              <ul className="list-group list-group-numbered shadow">
                <li className="list-group-item list-group-item-success list-group-item-action">Intégration simple</li>
                <li className="list-group-item list-group-item-success list-group-item-action">Formation minimale</li>
                <li className="list-group-item list-group-item-success list-group-item-action">Simple d'utilisation</li>
                <li className="list-group-item list-group-item-success list-group-item-action">Centralise vos données</li>
                <li className="list-group-item list-group-item-success list-group-item-action">Clarifie les données requises</li>
                <li className="list-group-item list-group-item-success list-group-item-action">Minimise les erreurs de suivi</li>
                <li className="list-group-item list-group-item-success list-group-item-action">Permet de visualiser les KPI souhaitées</li>
              </ul>

              <p className='fw-bold mt-4'>Limites</p>
              <ul className="list-group list-group-numbered shadow">
                <li className="list-group-item list-group-item-primary list-group-item-action">Archivage documentaire non automatisé</li>
                <li className="list-group-item list-group-item-primary list-group-item-action">Respect strict des contraintes</li>
                <li className="list-group-item list-group-item-primary list-group-item-action">Baisse des perfomances si le nombre d'entrées est trop important</li>
              </ul>
            </div>

            <div className="col-md-6 col-12 border-start py-3 hiddenDiv">
              <h5 className=''>Les parties de "OUTIL"</h5>
              <ul className="list-group shadow-sm">
                <li className="list-group-item list-group-item-action">
                  <p className='fw-bold'>Daschoard :</p>
                  <p>feuille vous permettant d'observation vos KPI.</p>
                </li>
                <li className="list-group-item list-group-item-action">
                  <p className='fw-bold'>Data loading :</p>
                  <p>feuille vous permettant d'ajouter, modifier ou supprimer de la donnée brute dans votre système.</p>
                </li>
                <li className="list-group-item list-group-item-action">
                  <p className='fw-bold'>TL (Transform, load):</p>
                  <p>feuille(s) de calcules et de transformations des données entrées.</p>
                </li>
                <li className="list-group-item list-group-item-action">
                  <p className='fw-bold'>Liste :</p>
                  <p>feuille comprenant les différentes variables de la solution et leurs contraintes.</p>
                </li>
              </ul>

              <h5 className='mt-5'>Dans le dossier "Outil de gestion Excel"</h5>
              <ul className="list-group shadow-sm">
                <li className="list-group-item list-group-item-action">
                  <p className='fw-bold'>SYSTÈME :</p>
                  <p>Votre système de gestion personnalisé.</p>
                </li>
                <li className="list-group-item list-group-item-action">
                  <p className='fw-bold'>Guide :</p>
                  <p>Version PDF de la documentation.</p>
                </li>
                <li className="list-group-item list-group-item-action">
                  <p className='fw-bold'>Archive DATA :</p>
                  <p>Dossier de sauvegarde de vos données passées.</p>
                </li>
                <li className="list-group-item list-group-item-action">
                  <p className='fw-bold'>Licence :</p>
                  <p>Document visant à certifier de la provenance du système.</p>
                </li>
              </ul>
            </div>

            <div className='mx-md-3 my-3 hiddenDiv'>
              <h5>Cas d'application : Optimisation de la gestion des ressources humaines pour une PME
              </h5>

              <p className='fw-bold'>Contexte de l'entreprise :</p>
              <p>
                Une petite entreprise spécialisée dans le domaine du marketing digital avec une cinquantaine d'employés fait face à des défis pour gérer efficacement les informations relatives à ses ressources humaines, notamment le suivi des congés, la gestion des performances et les informations de paie.
              </p>

              <p className='fw-bold'>Problématique :</p>

              <p>
                Les processus de gestion des ressources humaines sont dispersés et gérés manuellement à travers divers fichiers et systèmes, ce qui conduit à des erreurs, un manque de transparence et une perte de temps significative pour les employés et la gestion.
              </p>

              <p className='fw-bold'>Solution - "Outil Excel" personnalisé de ModalFini :</p>

              <p>
                Centralisation des données RH : L'outil regroupe toutes les données RH en un seul endroit, facilitant l'accès et la mise à jour par le département des ressources humaines.
                <br />
                <br />
                Automatisation des processus : L'outil est équipé de fonctions automatisées pour le calcul des jours de congé, le suivi des évaluations de performance et la préparation des données de paie, réduisant ainsi les erreurs humaines et économisant du temps.
                <br />
                <br />
                Rapports et analyses : Grâce à des fonctions avancées basées sur le principe ETL, l'outil offre des rapports personnalisables qui permettent une analyse détaillée des tendances du personnel, comme les taux de rotation ou les patterns de congés, aidant ainsi à la prise de décision stratégique.
                <br />
                <br />
                Interface conviviale : Conçu pour être intuitif, l'outil permet une prise en main facile par tous les membres de l'équipe RH, sans nécessité de compétences techniques avancées.
              </p>

              <p className='fw-bold'>Impact :</p>

              <p>
                L'utilisation de cet outil Excel personnalisé permet à l'entreprise de gérer de manière plus efficace et transparente ses ressources humaines. Les processus simplifiés et automatisés contribuent à une meilleure satisfaction des employés, à une gestion des performances plus cohérente, et à une administration des paies précise et ponctuelle.
              </p>
            </div>
          </div>

        </div>
      </div>

      <Footer />
      <MailPart />
      <CGUPart />
      <PlanPart />
    </>
  )
}

export default ToolsPage