import React from 'react'
import { Link } from 'react-router-dom'
import MailPart from './MailPart'

function Footer() {
    return (
        <>
            <div className="container-fluid mt-4 py-5 px-5">
                <div className="row TextFooter">
                    <div className="col border-start py-3">
                        <p>Produits</p>
                        <ul className="list-group">
                            <Link to='/Systeme'>
                                <li className="list-group-item list-group-item-light list-group-item-action">Système Excel</li>
                            </Link>
                            <Link to='/Outil'>
                                <li className="list-group-item list-group-item-light list-group-item-action">Outil Excel</li>
                            </Link>
                        </ul>
                    </div>
                    <div className="col border-start py-3">
                        <p>Liens</p>
                        <ul className="list-group">
                            <li className="list-group-item list-group-item-light list-group-item-action">Instagram</li>
                            <li className="list-group-item list-group-item-light list-group-item-action">Linkdeen</li>
                            <li className="list-group-item list-group-item-light list-group-item-action" data-bs-toggle="offcanvas" data-bs-target="#offcanvasMail" aria-controls="offcanvasMail">Nous contacter</li>
                        </ul>
                    </div>
                    <div className="col border-start py-3">
                        <p>Ressources</p>
                        <ul className="list-group">
                            <li className="list-group-item list-group-item-light list-group-item-action" data-bs-toggle="offcanvas" data-bs-target="#offcanvasCGU" aria-controls="offcanvasCGU">CGU</li>
                            <li className="list-group-item list-group-item-light list-group-item-action" data-bs-toggle="offcanvas" data-bs-target="#offcanvasPlan" aria-controls="offcanvasPlan">Plan du site</li>
                        </ul>
                    </div>
                </div>
                <p className='mt-4'>ModalFini<code className='text-dark fs-6 fw-lighter'>/&gt;</code></p>
                <p className='text-secondary'>© 2024 ModalFini</p>
            </div>

            <MailPart />
        </>
    )
}

export default Footer