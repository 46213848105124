import React from 'react'

function MailPart() {
    return (
        <>
            <div className="offcanvas offcanvas-bottom h-75" tabindex="-1" id="offcanvasMail" aria-labelledby="offcanvasBottomLabel">
                <div className="offcanvas-header shadow-sm">
                    <h5 className="offcanvas-title text-dark" id="offcanvasBottomLabel">✉️ Nous contacter</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body small">

                    <div className='d-flex justify-content-center mt-4'>
                        <p>📞 Téléphone :+33 06 47 76 85 71</p>
                    </div>

                    <div className='d-flex justify-content-center mt-4'>
                        <a className="btn btn-dark shadow w-75" href="mailto:contacte@modalfini.com?subject=Demande%20de%20devis%20-%20Excel Personalisé">Nous contacter par mail</a>
                    </div>

                    <div className='container p-md-5 p-2'>
                        Exemple de mail
                        <p className='text-secondary fs-md-6 fs-8 user-select-all bg-light p-5 rounded-3 border'>
                            "
                            Hello Paul 👋,<br />
                            Je me présente XXXXXXX, je travaille pour XXXX et je souhaiterais réaliser un Système / Outil Excel pro pour réaliser XXXXXXX.<br />
                            Description du besoin : ipsum dolor sit amet consectetur adipisicing elit. Inventore consequuntur nobis tempore laborum ex voluptatem quas mollitia distinctio alias blanditiis molestias illo beatae tempora ratione, dolorem vero at autem velit.<br /><br />
                            Vous pouvez me contacter au XXXXXXX<br /><br />
                            Merci par avance pour votre retour.<br />
                            Cordialement,<br /><br />
                            Mr./Mme. X
                            "
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MailPart