import React from 'react'
import { Link } from 'react-router-dom'

function PlanPart() {
  return (
    <>
      <div className="offcanvas offcanvas-bottom h-50" tabindex="-1" id="offcanvasPlan" aria-labelledby="offcanvasBottomLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title text-dark" id="offcanvasBottomLabel">🗺️ Plan du site web</h5>
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="d-flex justify-content-center offcanvas-body small">

          <div className='mt-4'>
            <ul className="list-group list-group-horizontal-lg">
              <Link to='/'>
                <li className="list-group-item list-group-item-action py-5" style={{ width: "45vw" }}>Home page 🏠</li>
              </Link>
              <li className="list-group-item border-0" style={{ width: "45vw" }} />
            </ul>
            <ul className="list-group list-group-horizontal-lg">
              <Link to='/Systeme'>
                <li className="list-group-item list-group-item-action py-5" style={{ width: "45vw" }}>Page système 🧰</li>
              </Link>
              <Link to='/Outil'>
                <li className="list-group-item list-group-item-action py-5" style={{ width: "45vw" }}>Page outil 🔧</li>
              </Link>
            </ul>
          </div>

        </div>
      </div>
    </>
  )
}

export default PlanPart