import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import MailPart from '../components/MailPart';
import CGUPart from '../components/CGUPart';
import PlanPart from '../components/PlanPart';
import '../styles/Pages.css';

function SystemePage() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  document.addEventListener('scroll', function () {
    var divs = document.querySelectorAll('.hiddenDiv'); // Sélection de toutes les divs avec la classe .hiddenDiv
    var screenPosition = window.innerHeight * 0.7; // 10% du bas de l'écran

    divs.forEach(function (div) {
      var divPosition = div.getBoundingClientRect().top; // Position de chaque div par rapport au viewport
      if (divPosition < screenPosition) {
        div.classList.add('visibleDiv');
      } else {
        div.classList.remove('visibleDiv'); // Optionnel, réinitialise l'animation si remonté
      }
    });
  });

  return (
    <>
      {/* Introduction  */}
      <div className='container-flex position-relative headerPageSeconde'>
        <div className='position-absolute top-50 start-50 w-md-75 w-50 translate-middle titreZone'>
          <Link to='/'>
            <p className='mt-4 mx-5 px-3 text-dark logo'>ModalFini<code className='text-dark fw-lighter logo'>/&gt;</code></p>
          </Link>
          <div className='text-center'>
            <h1 className='TitleHome'>Système Excel 🧰</h1>
          </div>
        </div>
      </div>

      {/* Body */}
      <div className="container-fluid body border-top pb-5" style={{ minHeight: '100vh' }}>
        <div className="container mt-4">

          <div className="row align-items-end">
            <div className="col-md-6 col-12">
              <div className="container p-3 p-md-5">
                <Link to='/'>
                  <button type="button" className="btn btn-light w-100">Home</button>
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="container p-3 p-md-5">
                <p>Besoin d'une solution plus simple ?</p>
                <Link to='/outil'>
                  <button type="button" className="btn btn-dark border w-100 shadow">Outils Excel</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="w-100 rounded-2 Illustration ImgSys shadow-sm border" />

          <ul className="list-group mt-5 shadow-sm hiddenDiv">
            <li className="list-group-item list-group-item-action">Dossier SYSTÈME</li>
            <li className="list-group-item list-group-item-action" style={{ paddingLeft: '50px' }}>↳ Guide</li>
            <li className="list-group-item list-group-item-action text-primary" style={{ paddingLeft: '50px' }}>↳ SYSTÈME</li>
            <li className="list-group-item list-group-item-action" style={{ paddingLeft: '50px' }}>↳ Dossier DATA</li>
            <li className="list-group-item list-group-item-action" style={{ paddingLeft: "100px" }}>↳ Archive DATA</li>
            <li className="list-group-item list-group-item-action" style={{ paddingLeft: "100px" }}>↳ Anciennes versions</li>
            <li className="list-group-item list-group-item-action" style={{ paddingLeft: "100px" }}>↳ Archive VBA</li>
            <li className="list-group-item list-group-item-action" style={{ paddingLeft: "100px" }}>↳ Licence</li>
          </ul>

          <div className="container p-3 p-md-5">
            <button type="button" className="btn btn-dark w-100 shadow" data-bs-toggle="offcanvas" data-bs-target="#offcanvasMail" aria-controls="offcanvasMail">Nous contacter</button>
          </div>

          <div className="row mt-5">
            <div className="col-md-6 col-12 border-start py-3 hiddenDiv">
              <h5 className=''>Description générale</h5>
              <p>
                Cette solution est idéale pour les entreprises ayant besoin de gérer plusieurs domaines d'activité en même temps. En offrant un système similaire à un mini ERP, vous permettez une intégration et une vue d'ensemble efficaces des opérations de l'entreprise. La structure basée sur le principe ETL est également un atout majeur, car elle facilite la manipulation et l'analyse des données, rendant le système extrêmement fonctionnel et adaptable.
              </p>

              <p className='fw-bold'>Avantages</p>
              <ul className="list-group list-group-numbered shadow">
                <li className="list-group-item list-group-item-success list-group-item-action">Intégration simple</li>
                <li className="list-group-item list-group-item-success list-group-item-action">Formation minimale</li>
                <li className="list-group-item list-group-item-success list-group-item-action">Centralise vos données</li>
                <li className="list-group-item list-group-item-success list-group-item-action">Clarifie les données requises</li>
                <li className="list-group-item list-group-item-success list-group-item-action">Minimise les erreurs de suivi</li>
                <li className="list-group-item list-group-item-success list-group-item-action">Permet de visualiser les KPI souhaitées</li>
                <li className="list-group-item list-group-item-success list-group-item-action">Permet de croiser des sources d'niformations multiples</li>
                <li className="list-group-item list-group-item-success list-group-item-action">Permet d'anticiper d'éventuelle problèmes globaux</li>
              </ul>

              <p className='fw-bold mt-4'>Limites</p>
              <ul className="list-group list-group-numbered shadow">
                <li className="list-group-item list-group-item-primary list-group-item-action">Archivage documentaire non automatisé</li>
                <li className="list-group-item list-group-item-primary list-group-item-action">Respect strict des contraintes</li>
                <li className="list-group-item list-group-item-primary list-group-item-action">Baisse des perfomances si le nombre d'entrées est trop important</li>
              </ul>
            </div>

            <div className="col-md-6 col-12 border-start py-3 hiddenDiv">
              <h5 className=''>Les parties du "SYSTEME"</h5>
              <ul className="list-group shadow-sm">
                <li className="list-group-item list-group-item-action">
                  <p className='fw-bold'>Daschoard :</p>
                  <p>feuille(s) vous permettant d'observation vos KPI.</p>
                </li>
                <li className="list-group-item list-group-item-action">
                  <p className='fw-bold'>Data loading :</p>
                  <p>feuille(s) vous permettant d'ajouter, modifier ou supprimer de la donnée brute dans votre système.</p>
                </li>
                <li className="list-group-item list-group-item-action">
                  <p className='fw-bold'>TL (Transform, load):</p>
                  <p>feuille(s) de calcules et de transformations des données entrées.</p>
                </li>
                <li className="list-group-item list-group-item-action">
                  <p className='fw-bold'>Data brut :</p>
                  <p>feuille(s) de données brutes secondaires.</p>
                </li>
                <li className="list-group-item list-group-item-action">
                  <p className='fw-bold'>Liste :</p>
                  <p>feuille comprenant les différentes variables de la solution et leurs contraintes.</p>
                </li>
              </ul>

              <h5 className='mt-5'>Dans le dossier "Système de gestion Excel"</h5>
              <ul className="list-group shadow-sm">
                <li className="list-group-item list-group-item-action">
                  <p className='fw-bold'>SYSTÈME :</p>
                  <p>Votre système de gestion personnalisé.</p>
                </li>
                <li className="list-group-item list-group-item-action">
                  <p className='fw-bold'>Guide :</p>
                  <p>Version PDF de la documentation.</p>
                </li>
                <li className="list-group-item list-group-item-action">
                  <p className='fw-bold'>Archive DATA :</p>
                  <p>Dossier de sauvegarde de vos données passées.</p>
                </li>
                <li className="list-group-item list-group-item-action">
                  <p className='fw-bold'>Archive VERSION :</p>
                  <p>Dossier de sauvegarde des versions précédentes de votre solution. Celui-ci est mis à jour à chaque maintenance, pour vous permettre de ne rien perdre.</p>
                </li>
                <li className="list-group-item list-group-item-action">
                  <p className='fw-bold'>Archive VBA :</p>
                  <p>Dossier de sauvegarde du code et des algorythmes de votre solution.</p>
                </li>
                <li className="list-group-item list-group-item-action">
                  <p className='fw-bold'>Licence :</p>
                  <p>Document visant à certifier de la provenance du système.</p>
                </li>
              </ul>
            </div>

            <div className='mx-md-3 my-3 hiddenDiv'>
              <h5>Cas d'application : Gestion intégrée pour une entreprise de fabrication de moyenne taille
              </h5>

              <p className='fw-bold'>Contexte de l'entreprise :</p>
              <p>
                Une entreprise de fabrication de composants électroniques, employant environ 200 personnes, gère plusieurs départements clés : production, approvisionnement, ventes, et ressources humaines. Elle fait face à des défis en termes de synchronisation des opérations entre les départements, de suivi des stocks en temps réel et de gestion efficace des ressources humaines
              </p>

              <p className='fw-bold'>Problématique :</p>

              <p>
                L'entreprise éprouve des difficultés à obtenir une vue d'ensemble cohérente de ses opérations, ce qui conduit à des retards de production, une planification inadéquate des stocks, et des inefficacités dans la gestion des ressources humaines.
              </p>

              <p className='fw-bold'>Solution - "Système Excel" personnalisé de ModalFini :</p>

              <p>
                Intégration des données : Le système collecte des données de tous les départements et les archive dans un dossier data centralisé, facilitant un accès et une mise à jour en temps réel.
                <br />
                <br />
                Fonctionnalités ETL : Les données de production, d'approvisionnement, de ventes et de RH sont extraites, transformées et chargées de manière à offrir des rapports et des dashboards clairs et actualisés, aidant à la prise de décision.
                <br />
                <br />
                Gestion centralisée : Le fichier Excel "SYSTEME" agit comme un mini ERP, permettant la gestion des commandes, des stocks, des calendriers de production, et des informations du personnel, le tout accessible dans un système unifié.
                <br />
                <br />
                Documentation et formation : Un guide PDF est fourni pour faciliter l'utilisation du système par tous les utilisateurs, assurant une transition en douceur et une adoption rapide par les équipes.
              </p>

              <p className='fw-bold'>Impact :</p>

              <p>
                L'implémentation de ce système permet à l'entreprise de coordonner efficacement ses opérations, d'optimiser la gestion des stocks, d'améliorer la planification des ressources de production et de simplifier la gestion des ressources humaines. Cela entraîne une réduction des coûts opérationnels, une amélioration de la satisfaction client grâce à des délais de livraison plus courts, et une meilleure gestion des ressources humaines.
              </p>
            </div>
          </div>

        </div>
      </div>

      {/* Footer */}
      <Footer />
      <MailPart />
      <CGUPart />
      <PlanPart />
    </>
  )
}

export default SystemePage