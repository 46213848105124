import React from 'react'

function CGUPart() {
  return (
    <>
      <div className="offcanvas offcanvas-bottom h-50" tabindex="-1" id="offcanvasCGU" aria-labelledby="offcanvasBottomLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title text-dark" id="offcanvasBottomLabel">📄 CGU</h5>
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body small">

          <div className='container'>
            <h6 className='fs-6'>Conditions Générales d'Utilisation (CGU)</h6>
            <br />
            <div id="item-1-1">
              <h6>Article 1 - Objet</h6>
              <label>Les présentes conditions régissent l'utilisation du site web ModalFini. En accédant au site, l'utilisateur s'engage à respecter les présentes conditions.</label>
            </div>
            <div id="item-1-2">
              <h6>Article 2 - Propriété intellectuelle</h6>
              <label>Tous les éléments du site web ModalFini sont et restent la propriété intellectuelle exclusive de ModalFini.com. L'utilisateur s'engage à ne pas reproduire, exploiter, ou utiliser à quelque titre que ce soit, même partiellement, les éléments du site.</label>
            </div>
            <div id="item-1-3">
              <h6>Article 3 - Responsabilité</h6>
              <label>ModalFini s'efforce de fournir des informations précises sur le site, mais ne saurait garantir l'exactitude de ces informations. ModalFini ne pourra être tenu responsable des dommages directs ou indirects résultant de l'utilisation du site.</label>
            </div>
            <div id="item-1-3">
              <h6>Article 4 - Utilisation des données personnelles</h6>
              <label>ModalFini collecte des données personnelles pour le bon fonctionnement du site. L'utilisateur peut consulter la politique de confidentialité pour en savoir plus sur la collecte et l'utilisation de ses données.</label>
            </div>
            <div id="item-1-3">
              <h6>Article 5 - Modification des CGU</h6>
              <label>ModalFini se réserve le droit de modifier les présentes conditions à tout moment. Les utilisateurs seront informés des modifications par une notification sur le site.</label>
            </div>
            <div id="item-1-3">
              <h6>Article 6 - Droit applicable</h6>
              <label>Les présentes CGU sont soumises au droit applicable. Tout litige relatif à l'interprétation ou à l'exécution des présentes sera de la compétence exclusive des tribunaux compétents.</label>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default CGUPart